<template>
  <div>
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('completedProcess')"
      :headerMsg="$t('succesfully')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelOk')"
      imgSource="notification/success2.svg"
      typeModal="transaction"
      disableBtnNo="true"
      :onHandlerNo="buttonNoSuccess"
      :onHandlerYes="buttonOke"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('FailedSubmit')"
      :headerMsg="$t('failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('TryAgain')"
      imgSource="notification/failed.svg"
      disableBtnNo="true"
      typeModal="transaction"
      :onHandlerNo="cancelFailed"
      :onHandlerYes="buttonOkeFailed"
    />

    <notification-popup
      :dialog="dialogRemoveAccount"
      :contentMsg="$t('textDeleteAccount')"
      :headerMsg="$t('DeleteAccount')"
      colorheaderMsg="error--text"
      nameBtnNo="No"
      nameBtnYes="Yes"
      colorIcon="danger"
      :onHandlerNo="clickDeleteNoAccount"
      :onHandlerYes="clickDeleteAccount"
    />

    <div class="item-add">
      <v-container class="pt-0">
        <!-- <v-row justify="center" class="background-card">
          <v-col cols="4" class="pt-0 pb-0">
            <v-img v-if="gender_ === 'm'"
              class="around mt--5"
              :src="require('@/assets/photo-profile.png')"
            ></v-img>
            <v-img v-if="gender_ === 'f'"
              class="around"
              :src="require('@/assets/female-profile.png')">
            </v-img>
          </v-col>
        </v-row> -->
        <v-row justify="center">
          <v-col class="text-center pt-0">
            <b>
              <span class="h4-black break-anywhere">
                {{ formEdit.fullName }}
              </span>
            </b>
            <br />
            <p class="mb-0 text">{{ formEdit.email }}</p>
          </v-col>
        </v-row>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("username")
              }}</label>
              <v-text-field
                class="rounded-md"
                v-model="formEdit.username"
                persistent-hint
                type="text"
                :placeholder="$t('username')"
                required
                filled
                outlined
                disabled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("email")
              }}</label>
              <v-text-field
                v-model="formEdit.email"
                type="email"
                :placeholder="$t('email')"
                required
                filled
                outlined
                disabled
                dense
                :error="isDangerEmail()"
                :success="isSuccessEmail()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("first_name")
              }}</label>
              <v-text-field
                v-model="formEdit.firstName"
                :placeholder="$t('first_name')"
                name="first_name"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|max:200'"
                :error-messages="errors.first('first_name')"
                type="text"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("last_name")
              }}</label>
              <v-text-field
                v-model="formEdit.lastName"
                :placeholder="$t('last_name')"
                name="last_name"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|max:200'"
                :error-messages="errors.first('last_name')"
                type="text"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 
          <label class="label-content">{{$t('phoneNumber')}}</label> -->
          <v-row>
            <v-col cols="12 pb-0 pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("MobileNumber")
              }}</label>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-autocomplete
                class="autocomplete-cid"
                v-model="formEdit.phoneCID"
                type="text"
                :items="countries"
                item-text="defaultPlaceholder"
                item-value="dialCode"
                placeholder="Select National"
                name="PhoneCID"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required'"
                :error-messages="errors.first('PhoneCID')"
                outlined
                dense
                return-object
              >
                <template slot="item" slot-scope="data">
                  <p class="font-weight-normal">
                    {{ data.item.name }}
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <v-text-field
                v-model="formEdit.phoneNumber"
                :prefix="formEdit.phoneCID.dialCode || userData.phoneCID"
                :placeholder="$t('phoneNumber')"
                name="phoneNumber"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|min:4|max:15'"
                :error-messages="errors.first('phoneNumber')"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <vue-tel-input class="tel-input" v-on:country-changed="countryChanged"
          v-model="phone_number"></vue-tel-input> -->
          <v-row>
            <v-col class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("DateOfBirth")
              }}</label>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    name="DateOfBirth"
                    :color="$partnerACL.getButtonColor()"
                    v-validate="'required'"
                    :rules="[birthOfDateRules.min]"
                    :value="computedDateFormattedMomentjs"
                    placeholder="dd-mm-yyyy"
                    readonly
                    required
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="formEdit.birthday = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="formEdit.birthday"
                  @change="menu1 = false"
                  :max="now()"
                  min="1900-01-01"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("gender")
              }}</label>
              <!-- <v-select
                v-model="formEdit.gender"
                v-bind:items="genderItem"
                item-text="name"
                item-value="code"
                dense
                outlined
              >
              </v-select> -->
              <v-autocomplete
                name="gender"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required'"
                :error-messages="errors.first('gender')"
                :items="genderItem"
                :placeholder="$t('SelectGender')"
                item-text="name"
                item-value="code"
                required
                v-model="formEdit.gender"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- <p @click="notifDeleteAccount()" class="text-capitalize error--text">
          Delete Account
        </p> -->
          <br /><br /><br />
        </v-form>
      </v-container>
    </div>

    <div class="footer">
      <v-row>
        <v-col class="text-center">
          <!-- <v-btn
            v-if="$partnerACL.isShowButton('save')"
            :color="$partnerACL.getButtonColor()"
            :disabled="!this.valid"
            rounded
            width="80%"
            @click="clickUpdateProfile()"
          >
            {{ $t("Save") }}
          </v-btn> -->
          <ac-button 
            :active="$partnerACL.isShowButton('save')"
            :name="$t('Save')" 
            :on-click-handler="clickUpdateProfile"
            :is-disabled="!this.valid"
            :width="'80%'"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import moment from "moment";
import allCountries from "@/components/Countries";
import AcButton from "@/components/AcButton";
import { Validator } from 'vee-validate';

export default {
  name: "EditProfile",
  components: {
    NotificationPopup,
    AcButton
    // VueTelInput,
  },
  data() {
    return {
      dialogRemoveAccount: false,
      notifSuccess: false,
      notifFailed: false,
      valid: false,
      formEdit: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneCID: "",
        phoneNumber: "",
        birthday: "",
        gender: "",
      },
      phone_number: "",
      isPhoneValid: false,
      user_name: "",
      phone: "",
      birth_of_date: "",
      gender_: "",
      error_: "",
      genderItem: [
        {
          code: "female",
          name: this.$t("Female"),
        },
        {
          code: "male",
          name: this.$t("Male"),
        },
      ],
      // rules: {
      //   required: (value) => !/^\s*$/.test(value) || this.$t("Required"),
      //   min: (v) => v.length >= 6 || this.$t("MinSixCharacters"),
      //   emailMatch: () => this.$t("TheEmailPasswordEnteredDontMatch"),
      //   requiredPhone: (value) =>
      //     /^[0-9]+$/.test(value) || this.$t("RequiredSpesificNumber"),
      //   minPhone: (value) => value.length >= 7 || this.$t("MinSevenCharacters"),
      //   maxPhone: (value) =>
      //     value.length <= 15 || this.$t("MaxFifteenCharacters"),
      //   matchPhoneNumber: () => /^(([0-9]))$/,
      //   // matchPhoneNumber: () => /^((1[3578][0-9])+\d{8})$/,
      // },
      birthOfDateRules: {
        min: value => {
          const breakValue = String(value).split('-')
          const buildValue = breakValue[2] + '-' + breakValue[1] + '-' + breakValue[0]
          const minAge = moment(this.sevenYearAgo()).valueOf()
          const selectDate = moment(buildValue).valueOf()
          const checkMinAge = selectDate < minAge
          
          return checkMinAge || this.$t("BirthOfDateMin")
        }
      },
      countries: allCountries,
      menu1: false,
      // country: null,
      // bindProps: {
      //   mode: "international",
      //   defaultCountry: "VI",
      //   disabledFetchingCountry: false,
      //   disabled: false,
      //   disabledFormatting: false,
      //   placeholder: "Enter a phone number",
      //   required: false,
      //   enabledCountryCode: false,
      //   enabledFlags: true,
      //   preferredCountries: [],
      //   onlyCountries: [],
      //   ignoredCountries: [],
      //   autocomplete: "off",
      //   name: "telephone",
      //   maxLen: 25,
      //   wrapperClasses: "",
      //   inputClasses: "",
      //   dropdownOptions: {
      //     disabledDialCode: false
      //   },
      //   inputOptions: {
      //     showDialCode: false
      //   }
      // },
    };
  },
  async created() {
    Validator.extend('required', {
      getMessage: field => this.$t("RequiredErrorMessage", { field: this.$t(field) }),
      validate: value => value != ''
    });

    Validator.extend('min', {
      paramNames: ['min'],
      getMessage: (field, [min]) => this.$t("RequiredMin", { field: this.$t(field), min }),
      validate: (value, { min }) => String(value).length >= min
    });
    
    Validator.extend('max', {
      paramNames: ['max'],
      getMessage: (field, [max]) => this.$t("RequiredMax", { field: this.$t(field), max }),
      validate: (value, { max }) => String(value).length <= max
    });
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.formEdit.birthday
        ? moment(this.formEdit.birthday).format("DD-MM-YYYY")
        : "";
    },
    validateForm() {
      return (
        this.valid &&
        this.formEdit.firstName != "" &&
        this.formEdit.lastName != "" &&
        this.formEdit.userName != "" &&
        this.formEdit.email != "" &&
        this.formEdit.birthday != "" &&
        this.formEdit.phoneNumber != "" &&
        this.formEdit.gender != ""
      );
    },
    userData() {
      return this.$store.state.auth.userData
    },
    isPhoneNumberChanged() {
      const currentPhoneNumber = this.userData.phoneCID + this.userData.phoneNumber
      const updatedPhoneNumber = this.formEdit.phoneCID.dialCode ? this.formEdit.phoneCID.dialCode + this.formEdit.phoneNumber : this.userData.phoneCID + this.formEdit.phoneNumber
      return currentPhoneNumber != updatedPhoneNumber;
    }
  },
  mounted() {
    this.$store
      .dispatch("customer/detailCustomer")
      .then((response) => {
        this.full_name = response.fullName;
        this.formEdit = {
          ...response,
          birthday: moment(response.birthday).isAfter('1900-01-01') ? moment(response.birthday).format("YYYY-MM-DD") : "",
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    now() {
      return moment().format('YYYY-MM-DD')
    },
    sevenYearAgo() {
      return moment().subtract(7,'y').format('YYYY-MM-DD');
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    acceptNumber(item) {
      var x = item.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      item = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      // console.log("accept number", item);
    },
    // isNumber() {
    //   var re =
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   // re.test(this.formEdit.email) ? console.log('mdi-check') : console.log('mdi')
    //   // console.log("as", re.test(this.formEdit.email))
    //   let data = "";
    //   if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == true) {
    //     data = "mdi-check";
    //   } else if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == false) {
    //     data = "mdi-alert-circle-outline";
    //   }
    //   return data;
    // },
    // isEmailValid() {
    //   var re =
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   // re.test(this.formEdit.email) ? console.log('mdi-check') : console.log('mdi')
    //   // console.log("as", re.test(this.formEdit.email))
    //   let data = "";
    //   if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == true) {
    //     data = "mdi-check";
    //   } else if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == false) {
    //     data = "mdi-alert-circle-outline";
    //   }
    //   return data;
    // },

    isSuccessEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.formEdit.email) ? console.log('mdi-check') : console.log('mdi')
      let data = false;
      if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == true) {
        data = false;
      } else if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == false) {
        data = true;
      }

      return data;
    },
    isDangerEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let data = false;
      if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == true) {
        data = false;
      } else if (this.formEdit.email.length > 0 && re.test(this.formEdit.email) == false) {
        data = true;
      }

      return data;
    },
    clickUpdateProfile() {
      if (this.validateForm) {
        let caller_id;
        if (this.formEdit.phoneCID.dialCode == undefined) {
          caller_id = this.formEdit.phoneCID;
        } else {
          caller_id = this.formEdit.phoneCID.dialCode;
        }

        const payload = {
          firstName: this.formEdit.firstName,
          lastName: this.formEdit.lastName,
          fullName: this.formEdit.firstName + ' ' + this.formEdit.lastName,
          phoneCID: caller_id,
          phoneNumber: this.formEdit.phoneNumber,
          birthday: moment(this.formEdit.birthday).format("YYYY-MM-DD"),
          gender: this.formEdit.gender,
          isPhoneNumberChanged: this.isPhoneNumberChanged
        };
        this.$store
          .dispatch("customer/editProfile", payload)
          .then(async (response) => {
            if (response.status == true) {
              await this.$store.dispatch("auth/refreshCustomerUserData")
              this.notifSuccess = true;
            } else {
              this.notifFailed = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    buttonOke() {
      this.notifSuccess = false;
      // this.$router.push({ name: "About" });
      const saveButton = this.$partnerACL.getMetaButton('save');
      if (saveButton) {
        if (saveButton.menu_type == "wsc_menu") {
          this.$router.push({ path: saveButton.redirect_path });
        } else {
          window.location = saveButton.redirect_path;
        }
      } else {
        this.$router.push({ name: "About" });
      }
    },
    buttonOkeFailed() {
      this.notifFailed = false;
    },
    buttonNoSuccess() {
      this.notifSuccess = false;
    },
    cancelFailed() {
      this.notifFailed = false;
    },
    clickDeleteNoAccount() {
      this.dialogRemoveAccount = false;
    },
    notifDeleteAccount() {
      this.dialogRemoveAccount = true;

      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
    clickDeleteAccount() {
      this.$router.push({ name: "About" });
    },
  },
};
</script>

<style lang="scss" scoped>
.around {
  border-radius: 50% !important;
}

.border-input {
  border-color: grey !important;
}

.text {
  font-size: 0.85rem;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .p-useraname {
    margin-top: -2%;
  }

  .p-email {
    margin-top: -1%;
  }

  .image-profile {
    width: auto;
    height: 100px;
  }

  .item-add {
    background-color: #ffffff;
    margin-top: 2%;
    width: 100%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    // box-shadow: 0px 1px 10px 0px;
  }

  .tel-input {
    height: 48px;
  }

  .autocomplete-cid {
    text-emphasis-color: #ffffff;
    // background: #4bb14e;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .p-useraname {
    margin-top: -10%;
  }

  .p-email {
    margin-top: -5%;
  }

  .image-profile {
    width: auto;
    height: 60px;
  }

  .item-add {
    width: 100%;
    margin-top: 2%;
    background-color: #ffffff;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    // box-shadow: 0px 1px 10px 0px;
  }

  .tel-input {
    height: 48px;
  }

  .autocomplete-cid {
    text-emphasis-color: #ffffff;
    // background: #4bb14e;
  }
}
</style>